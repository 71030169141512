import React, { FC } from "react";
import { Grid, Link, useMediaQuery, Theme } from "@mui/material";
import he from "he";
import { default as RouterLink } from "./NavigationLink";
import { SiteNavigationProps } from "./types";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import memoize from "@RHCommerceDev/utils/memoize";

const categoryToOmitOnCollapse = "Galleries";
const categoryToOmitBelowLarge = "RH Interior Design";

export const SiteNavigation: FC<SiteNavigationProps> = ({
  siteNavigation,
  isRhrSiteNavigation,
  styles
}) => {
  const env = useEnv();
  const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xl"));
  const showRHInteriorDesign = useMediaQuery("(min-width:1366px)");
  const featureSiteNavDrawer = yn(env?.FEATURE_REACT_SITE_NAV_GALLERY_OMIT);

  return (
    <>
      {siteNavigation?.childCategories?.map(navElement => {
        const omitGallery =
          navElement.displayName === categoryToOmitOnCollapse &&
          mdDown &&
          featureSiteNavDrawer;
        const omitRHInteriorDesign =
          navElement.displayName === categoryToOmitBelowLarge &&
          !showRHInteriorDesign;

        return omitGallery || omitRHInteriorDesign ? null : (
          <Grid item key={`siteNav-${navElement.id}`}>
            <Link
              component={RouterLink}
              to={navElement.targetUrl}
              underline="none"
              style={styles?.link?.root}
            >
              {isRhrSiteNavigation ? (
                <>
                  <span
                    style={{
                      fontFamily: "CaslonRHThin, Big Caslon, Palatino",
                      letterSpacing: "-2px",
                      marginRight: "4.5px"
                    }}
                  >
                    RH
                  </span>
                  <span>
                    {!/rh/i.test(navElement.displayName) &&
                      he.decode(navElement.displayName)}
                  </span>
                </>
              ) : (
                <span> {he.decode(navElement.displayName)} </span>
              )}
            </Link>
          </Grid>
        );
      })}
    </>
  );
};

export default memoize(SiteNavigation);
