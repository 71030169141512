import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { useHistory } from "react-router";
import {
  CATALOG_NAV_CONCEPTS_ID,
  CATALOG_NAV_CONCEPTS_ALTERNATIVE_ID,
  CATALOG_NAV_CONCEPTS_LABEL
} from "@RHCommerceDev/utils/constants";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import buildPath, { isForcedReloadPath } from "@RHCommerceDev/utils/buildPath";
import classNames from "classnames";
import memoize from "@RHCommerceDev/utils/memoize";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { useStyles } from "@RHCommerceDev/container-rhr-header/RHRCatalogNavigationDetails/style";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useIsoRedirect from "@RHCommerceDev/hooks-use-isoredirect";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import { useCookies } from "@RHCommerceDev/hooks/useCookies";
import { isSSRToggledCheck } from "@RHCommerceDev/utils/ssrHelpers";
import { PG_SEARCH_TERM } from "@RHCommerceDev/utils/constants";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import uniqBy from "lodash.uniqby";
import RHLink from "@RHCommerceDev/component-rh-link";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { duplicatePattern } from "@RHCommerceDev/utils/regex";

export interface CatalogNavigationDetailsProps {
  selectedCatalogNavItem: NavigationElement;
  onCategoryDrawerClose: () => void;
  hasNoSubCategories?: boolean;
  navigateToUrl?: (navigationItem: NavigationElement) => void;
  isSelected?: boolean;
  featureWaterworks?: boolean;
}

export const CatalogNavigationDetails: FC<CatalogNavigationDetailsProps> = ({
  selectedCatalogNavItem,
  onCategoryDrawerClose,
  navigateToUrl,
  hasNoSubCategories,
  isSelected,
  featureWaterworks
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { app } = useAppData();
  const prefix = useLocalization();
  const env = useEnv();
  const { isConcierge } = useAppId();
  const { getResult } = isForcedReloadPath("");
  const isSSRToggled = isSSRToggledCheck();
  const FEATURE_FORCE_RELOAD_PATHS = isSSRToggled;
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const FEATURE_REMOVE_PG_TERM = yn(env.FEATURE_REMOVE_PG_TERM);
  const [, , removeCookie] = useCookies(["cgId", "pgId"]);
  const { setStorageValueWrapper } = useCookiesWithPermission();

  const [selectedChildCategoryIndex, setSelectedChildCategoryIndex] = useState<
    number | undefined
  >(isConcierge ? 0 : undefined);
  const [_isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const isConceptsMenu = useMemo(
    () =>
      selectedCatalogNavItem?.id === CATALOG_NAV_CONCEPTS_ID ||
      selectedCatalogNavItem?.id === CATALOG_NAV_CONCEPTS_ALTERNATIVE_ID ||
      selectedCatalogNavItem?.displayName
        ?.toUpperCase()
        .includes(CATALOG_NAV_CONCEPTS_LABEL?.toUpperCase()),
    [selectedCatalogNavItem]
  );

  const imageUrl: string = useMemo(
    () =>
      selectedCatalogNavItem?.childCategories?.[selectedChildCategoryIndex]
        ?.navImage ||
      selectedCatalogNavItem?.navImage ||
      "",
    [selectedChildCategoryIndex, selectedCatalogNavItem]
  );

  const handleNavigationClick = useCallback(
    (event: React.MouseEvent, navigationItem: NavigationElement) => {
      analyticsLoader(a => {
        a.emitAnalyticsEvent(
          // @ts-ignore
          document.querySelector("#spa-root > *")!,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { remove: "analytics-navigatingFromSearch" }
        );
      });

      if (isConceptsMenu && isConcierge) {
        return navigateToUrl?.(navigationItem);
      }
      if (navigationItem.linkType === "none") {
        onCategoryDrawerClose();
        return useIsoRedirect(navigationItem?.targetUrl, "windowOpen");
      }
      let updatedNavItem;
      if (navigationItem.targetUrl?.includes("room")) {
        const newUpdatedUrl = navigationItem.targetUrl.slice(
          0,
          navigationItem.targetUrl.indexOf("&")
        );
        updatedNavItem = `${prefix}${newUpdatedUrl}`;
      }
      if (navigationItem.targetUrl?.trim().indexOf("http") === 0) {
        if (!processEnvServer) {
          const target = event.target as HTMLElement;
          const id = (target?.parentNode as HTMLElement)?.id;
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: selectedCatalogNavItem?.displayName,
                  parentCat:
                    selectedCatalogNavItem?.childCategories &&
                    selectedCatalogNavItem?.childCategories[
                      selectedChildCategoryIndex ?? 0
                    ]?.displayName,
                  cat: navigationItem?.displayName,
                  catURL: navigationItem?.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        return navigateToUrl?.(navigationItem);
      } else {
        onCategoryDrawerClose();
        if (!processEnvServer) {
          const target = event.target as HTMLElement;
          const id = (target?.parentNode as HTMLElement)?.id;
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: selectedCatalogNavItem?.displayName,
                  parentCat:
                    selectedCatalogNavItem?.childCategories &&
                    selectedCatalogNavItem?.childCategories[
                      selectedChildCategoryIndex ?? 0
                    ]?.displayName,
                  cat: navigationItem?.displayName,
                  catURL: navigationItem?.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        let targetUrl = navigationItem?.targetUrl ?? "";

        // SR-2909: Rug Instock categories are not showing any products due to duplicate matches in the URL
        const removeDuplicateMatches = (url: string, pattern: RegExp) => {
          if (!url || !pattern) return url;
          const matches = url.match(pattern);

          if (matches && matches.length > 1) {
            const uniqueMatch = matches[0];
            return url.replace(uniqueMatch, "");
          }
          return url;
        };

        targetUrl = removeDuplicateMatches(targetUrl, duplicatePattern);

        const isPGPage = Boolean(
          navigationItem?.targetUrl?.includes("/catalog/category/products.jsp")
        );

        const searchParams = new URLSearchParams(targetUrl);

        if (
          !searchParams.has(PG_SEARCH_TERM) &&
          isPGPage &&
          !FEATURE_REMOVE_PG_TERM
        ) {
          searchParams.set(PG_SEARCH_TERM, `category:${navigationItem.id}`);
          targetUrl = decodeURIComponent(searchParams.toString());
        }
        const basePath =
          updatedNavItem && updatedNavItem.includes("room")
            ? updatedNavItem
            : buildPath(
                FEATURE_INTERNATIONAL
                  ? `${prefix}${targetUrl}`
                  : `${targetUrl}`,
                {
                  sale: app.saleContextFilter,
                  fromNav:
                    targetUrl?.includes("new-results") ||
                    targetUrl?.includes("results")
                      ? "true"
                      : null
                }
              );
        const params = {} as any;
        basePath.split("&").map(item => {
          const [key, value] = item.split("=");
          return (params[key] = value);
        });
        /*
          the SSR pages (PG/CG/Search/PDP) should do a full page load to load from the server and
          redis cache instead of using the react router and loading on the client.
        */
        if (isSSRToggled || getResult(basePath)) {
          window.location.href = basePath;
        } else {
          history.push(basePath);
        }
        // Setting Up Catalog Object To Track Navigation Path
        if (!processEnvServer) {
          localStorage.removeItem("analytics-navCatalog");
          localStorage.removeItem("fromNav");
          localStorage.removeItem("analytics-category");
          const navCatalog = {
            topCat: {
              id: selectedCatalogNavItem?.id,
              title: selectedCatalogNavItem?.displayName
            },
            parentCat: {
              id:
                selectedCatalogNavItem?.childCategories &&
                selectedCatalogNavItem?.childCategories[
                  selectedChildCategoryIndex ?? 0
                ]?.id,
              title:
                selectedCatalogNavItem?.childCategories &&
                selectedCatalogNavItem?.childCategories[
                  selectedChildCategoryIndex ?? 0
                ]?.displayName
            },
            cat: { id: navigationItem?.id, title: navigationItem?.displayName }
          };
          setStorageValueWrapper({ storageKey: "fromNav", value: "true" });
          setStorageValueWrapper({
            storageKey: "analytics-navCatalog",
            value: JSON.stringify(navCatalog)
          });
          memoryStorage.setItem("fromNav", true);
        }
      }
    },
    [
      onCategoryDrawerClose,
      setStorageValueWrapper,
      prefix,
      navigateToUrl,
      selectedCatalogNavItem?.displayName,
      selectedCatalogNavItem?.childCategories,
      selectedCatalogNavItem?.id,
      selectedChildCategoryIndex,
      FEATURE_INTERNATIONAL,
      app.saleContextFilter,
      isSSRToggled,
      getResult,
      history,
      setStorageValueWrapper
    ]
  );

  useEffect(() => {
    setIsImageLoaded(false);
  }, [imageUrl]);

  useEffect(() => {
    setSelectedChildCategoryIndex(isConcierge ? 0 : undefined); //resetting child category selection when a different concept is selected
  }, [selectedCatalogNavItem, isSelected]);

  const handleSelectChildCategory = useCallback(
    (event: React.MouseEvent, item, index) => {
      if (isConceptsMenu) {
        return;
      }

      if (item?.childCategories?.length) {
        setSelectedChildCategoryIndex(index);
      } else {
        if (!processEnvServer) {
          const target = event.target as HTMLElement;
          const id = (target?.parentNode as HTMLElement)?.id;
          // Setting up analytics events for elements that dont have child catgeories
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: selectedCatalogNavItem?.displayName,
                  parentCat: item?.displayName,
                  cat: item?.displayName,
                  catURL: item?.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        onCategoryDrawerClose();
        if (!processEnvServer) {
          localStorage.removeItem("analytics-navCatalog");
          localStorage.removeItem("fromNav");
          localStorage.removeItem("analytics-category");
          const navCatalog = {
            topCat: {
              id: selectedCatalogNavItem?.id,
              title: selectedCatalogNavItem?.displayName
            },
            parentCat: { id: item?.id, title: item?.displayName },
            cat: { id: item?.id, title: item?.displayName }
          };
          setStorageValueWrapper({ storageKey: "fromNav", value: "true" });
          setStorageValueWrapper({
            storageKey: "analytics-navCatalog",
            value: JSON.stringify(navCatalog)
          });
          memoryStorage.setItem("fromNav", true);
        }
        history.push(buildPath(`${prefix}${item?.targetUrl}`));
      }
    },
    [history, selectedChildCategoryIndex, selectedCatalogNavItem]
  );

  const allMenuItemCategories = useMemo(() => {
    const categories = selectedCatalogNavItem?.childCategories?.reduce(
      (acc, value) => {
        return [...acc, ...(value?.childCategories ?? [])];
      },
      []
    );
    return uniqBy(categories, "targetUrl");
  }, [selectedCatalogNavItem?.childCategories]);

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={6}
          id={isConceptsMenu ? "brand-links-container" : ""}
          style={{
            paddingRight: 40,
            borderRight: hasNoSubCategories ? "none" : "0.5px solid #E1E1E1"
          }}
        >
          <List
            style={{ width: hasNoSubCategories ? 160 : 178 }}
            disablePadding
            role={"menu"}
            aria-label={selectedCatalogNavItem?.displayName ?? ""}
          >
            {selectedCatalogNavItem?.childCategories?.map((item, index) => {
              if (item?.isWaterworks && featureWaterworks) return;
              const isSelected = selectedChildCategoryIndex === index;
              const isSaleItem =
                item.displayName?.trim()?.toLowerCase() === "sale";
              const isBrTag = item.displayName?.includes("<br>");
              const itemDisplayName = item?.displayName?.replace(/<br>/g, "");
              const haveSubMenu = item?.childCategories?.length > 0 ?? false;
              return (
                <>
                  <ListItem
                    id={`rhrCtalogNavigationDetails_navigation-${item.id}`}
                    role="none"
                    className={classNames(
                      "!p-0 !decoration-[rgba(0,0,0,1)] underline-offset-[4px] [text-decoration-thickness:1px] transition-colors duration-700 hover:underline hover:cursor-pointer",
                      {
                        [classes.conceptListItem]: isConceptsMenu,
                        ["!text-[#CA2022] !text-decoration-[#CA2022]"]:
                          isSaleItem
                      },
                      isConcierge &&
                        index ===
                          selectedCatalogNavItem!.childCategories!.length - 1
                        ? ""
                        : "!mb-4",
                      FEATURE_FORCE_RELOAD_PATHS
                        ? isConcierge &&
                          index ===
                            selectedCatalogNavItem!.childCategories!.length - 1
                          ? ""
                          : "!mb-4"
                        : "",
                      FEATURE_FORCE_RELOAD_PATHS ? classes.ssrListItem : ""
                    )}
                    style={
                      isSelected
                        ? {
                            textDecoration: "underline"
                          }
                        : {}
                    }
                    key={`navigation-${item.id}`}
                    data-analytics-url={isConceptsMenu ? item.targetUrl : ""}
                    onClick={e =>
                      isConceptsMenu ? handleNavigationClick(e, item) : null
                    }
                  >
                    <Typography
                      component="span"
                      role="menuitem"
                      aria-label={`${itemDisplayName}`}
                      aria-expanded={haveSubMenu ? isSelected : undefined}
                      tabIndex={0}
                      onKeyDown={handleKeyboardPressWithEvent(e =>
                        handleSelectChildCategory(e, item, index)
                      )}
                      className={classNames([
                        isConceptsMenu ? "uppercase" : "capitalize",
                        "!leading-[16px] text-[11px] tracking-[0.04em]",
                        {
                          ["underline decoration-rgba(0, 0, 0, 1)"]:
                            isSelected && isConcierge,
                          ["!text-[#CA2022] !text-decoration-[#CA2022]"]:
                            isSaleItem
                        }
                      ])}
                      style={
                        selectedChildCategoryIndex == null || isSelected
                          ? {
                              color: "#000000"
                            }
                          : {
                              color: "#898886"
                            }
                      }
                      variant="caption"
                      onMouseEnter={
                        isConcierge
                          ? () => setSelectedChildCategoryIndex(index)
                          : undefined
                      }
                      onClick={e => handleSelectChildCategory(e, item, index)}
                      dangerouslySetInnerHTML={{
                        __html: itemDisplayName
                      }}
                    />
                  </ListItem>
                  {isBrTag && <br />}
                </>
              );
            })}
          </List>
        </Grid>
        {isConceptsMenu ? (
          <>{hasNoSubCategories ? <></> : <Grid item xs={6}></Grid>}</>
        ) : (
          <Grid
            xs={6}
            style={{
              paddingLeft: 40,
              display: selectedChildCategoryIndex === undefined ? "none" : ""
            }}
          >
            <List
              style={{ width: hasNoSubCategories ? 160 : 178 }}
              disablePadding
              role="menu"
              aria-label={
                selectedCatalogNavItem?.childCategories?.[
                  selectedChildCategoryIndex ?? 0
                ]?.displayName ?? ""
              }
            >
              {(selectedChildCategoryIndex !== undefined
                ? selectedCatalogNavItem?.childCategories?.[
                    selectedChildCategoryIndex ?? 0
                  ]?.childCategories
                : allMenuItemCategories
              )?.map(item => {
                if (item?.isWaterworks && featureWaterworks) return;

                const pattern = /([^<br>]+)(<br>)/g;
                const hasEndBr =
                  item?.displayName?.trim()?.endsWith("<br>") ?? false;
                let displayName = item?.displayName
                  ?.replace(/<br>\s*$/, "")
                  ?.replace(pattern, "<div>$1</div>$2");

                return (
                  <ListItem
                    id={`rhrCtalogNavigationDetails_concepts-navigation-${item.id}`}
                    key={`navigation-${item.id}`}
                    role="none"
                    className={classNames(
                      "mb-4 !decoration-[rgba(0,0,0,1)] underline-offset-[4px] [text-decoration-thickness:1px] transition-colors duration-700 hover:underline hover:cursor-pointer",
                      classes.padding0,
                      "hover:underline hover:decoration-[rgba(0,0,0,1)] !p-0",
                      FEATURE_FORCE_RELOAD_PATHS ? "!mb-4" : "",
                      hasEndBr ? "sm:!mb-8" : ""
                    )}
                    onClick={e => {
                      /*
                        removing cookie when page is reloaged because
                        of SSR n back button
                      */
                      if (isSSRToggled) {
                        removeCookie("cgId", {
                          path: "/"
                        });
                        removeCookie("pgId", {
                          path: "/"
                        });
                        removeCookie("sipId", {
                          path: "/"
                        });
                      }
                      handleNavigationClick(e, item);
                    }}
                  >
                    <RHLink
                      role="menuitem"
                      aria-label={`${item.displayName}`}
                      href={`${prefix}${item?.targetUrl}${
                        item?.targetUrl?.includes("?") ? "&" : "?"
                      }`}
                      style={{ pointerEvents: "none" }}
                    >
                      <Typography
                        component="p"
                        variant="caption"
                        className={classNames(
                          "capitalize",
                          "!leading-[16px] text-[11px] tracking-[0.04em]"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: displayName
                        }}
                      />
                    </RHLink>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export const CatalogNavigationDetailsWrapper: FC<
  CatalogNavigationDetailsProps
> = props => {
  const navigateToUrl = (navigationItem: NavigationElement) => {
    useIsoRedirect(navigationItem.targetUrl, "windowAssign");
  };

  return <CatalogNavigationDetails navigateToUrl={navigateToUrl} {...props} />;
};

export default memoize(CatalogNavigationDetailsWrapper);
