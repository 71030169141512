import React, {
  FC,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
  useCallback
} from "react";
import { Link as RouterLink } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Backdrop,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { queryTypeahead } from "@RHCommerceDev/graphql-client/queries/search";
import { useApolloClient } from "@apollo/client";
import useState from "@RHCommerceDev/hooks/useState";
import {
  TYPEAHEAD_START,
  TYPEAHEAD_DEBOUNCE_KEY,
  RHR_BACKDROP_OVERLAY
} from "@RHCommerceDev/utils/constants";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useHistory } from "react-router";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import RHSearchIcon from "@RHCommerceDev/icon-search";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import SearchTextField from "@RHCommerceDev/container-search-field/SearchTextField";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import RHLink from "@RHCommerceDev/component-rh-link";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import classNames from "classnames";
import he from "he";
import memoize from "@RHCommerceDev/utils/memoize";
import {
  SEE_ALL,
  PRODUCTS,
  CATEGORIES
} from "@RHCommerceDev/resources/search-results-resource.json";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { codePattern } from "@RHCommerceDev/utils/regex";
import yn from "yn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerClass: {
      width: "100%"
    },
    hoverSearch: {
      zIndex: 10,
      "&:hover:not(.Mui-disabled):before": { borderBottom: "1px solid #999999" }
    },
    overlaySearch: {
      position: "absolute",
      backgroundColor: "white",
      zIndex: 10,
      width: "100%",
      "&::before": {
        position: "absolute",
        left: "-100%",
        content: "' '",
        height: "-webkit-fill-available",
        backgroundColor: "white",
        right: 0
      }
    },
    overFlow: {
      overflowY: "auto"
    }
  })
);

export interface SearchFieldProps {
  onClose: () => void;
  searchResultText?: string;
  isHeaderSearch?: boolean;
  isRHRSearchField?: boolean;
  isMobileView?: boolean;
}

export const SearchField: FC<SearchFieldProps> = ({
  searchResultText = "",
  isHeaderSearch = true,
  isRHRSearchField = false,
  isMobileView = false,
  ...props
}) => {
  const theme = useTheme();
  const client = useApolloClient();
  const history = useHistory();
  const typographyClasses = useTypographyStyles({
    keys: [
      "searchInput",
      "searchAutoSuggestResult",
      "autoSearchSectionTitle",
      "rhrSearchAutoSuggestResult"
    ]
  });
  const classes = useStyles();
  const env = useEnv();
  const isFeaturetailwindIcon = yn(env.FEATURE_TAILWIND_ICON);
  const prefix = useLocalization();

  const [searchText, setSearchText] = useState<string>("");
  /** @ts-ignore */
  const [queryResults, setQueryResults] = useState<SearchAutoSuggestResponse>(
    {}
  );
  const [showTypeAhead, setShowTypeAhead] = useState(false);
  const { app, setApp } = useAppData();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("lg"));
  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();

  useEffect(() => {
    if (!isHeaderSearch) {
      setSearchText(searchResultText);
    }
    // TODO: Fix eslint error and remove this comment block
    // React Hook useEffect has a missing dependency: 'isHeaderSearch'. Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultText]);

  useEffect(() => {
    if (searchText.length < TYPEAHEAD_START) {
      setQueryResults({});
      return;
    }

    const observable = client
      .watchQuery<Query>({
        query: queryTypeahead,
        variables: {
          ntt: searchText
        },
        context: {
          debounceKey: TYPEAHEAD_DEBOUNCE_KEY
        }
      })
      .subscribe(({ data }) => {
        /** @ts-ignore */
        setQueryResults(data.autoSuggest);
        /** @ts-ignore */
        if (data?.autoSuggest?.searchQueryId) {
          const fusionData = {
            /** @ts-ignore */
            fusion_id: data.autoSuggest.searchQueryId,
            /** @ts-ignore */
            fusion_filter: data.autoSuggest?.filterQueries
          };
          setCookieWrapper("navigating_from_search", "true");
          if (!processEnvServer) {
            setStorageValueWrapper({
              storageKey: "fusion_data",
              value: JSON.stringify(fusionData)
            });
          }
        }
      });

    return () => {
      observable?.unsubscribe();
    };
  }, [searchText, client, setCookieWrapper, setStorageValueWrapper]);

  const autoSuggestCategoryResults =
    queryResults?.categoryList?.categories ?? [];
  const autoSuggestProductResults = queryResults?.resultList?.records ?? [];
  const autoSuggestPromoResults = queryResults?.promo ?? [];

  const _onClose = useCallback(() => {
    props.onClose();
    if (!isHeaderSearch) {
      setShowTypeAhead(false);
      setSearchText("");
    }
  }, [props.onClose, isHeaderSearch]);

  const handleTypeahead = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCookieWrapper("search_access", "typeahead");
      setSearchText(event.target.value);
      if (!showTypeAhead && !isHeaderSearch) {
        setShowTypeAhead(true);
      }
    },
    [setCookieWrapper, showTypeAhead, isHeaderSearch]
  );

  const handleEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        !!searchText &&
        event.key === "Enter" &&
        !!codePattern.test(searchText)
      ) {
        setCookieWrapper("search_access", "direct");
        history.push(
          `${prefix}/search/results.jsp?Ntt=${encodeURIComponent(
            searchText
          )}&Ns=product.sale%7C1`
        );
        setApp({
          ...app,
          saleContextFilter: null
        });
        _onClose();
      }
    },

    [searchText, setCookieWrapper, history, setApp, app, _onClose]
  );

  const handleLinkClicked = useCallback(() => {
    setApp({
      ...app,
      saleContextFilter: null
    });
    _onClose();
  }, [_onClose, app, setApp]);
  const _onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const rhLinkOnClickCb = useCallback(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
        { remove: "analytics-searchtermclick" }
      )
    );
    handleLinkClicked();
  }, [handleLinkClicked]);

  const backdropOnClickCb = useCallback(() => _onClose(), [_onClose]);

  return (
    <div className={isHeaderSearch ? classes.headerClass : ""}>
      {isRHRSearchField && !isMobileView ? (
        <SearchTextField
          id="site-search-input"
          margin="normal"
          autoFocus={true}
          InputProps={{
            autoComplete: "off",
            value: searchText,
            className: classNames([classes.hoverSearch]),
            endAdornment: (
              <InputAdornment position="end">
                {!!searchText && (
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={_onClearSearchText}
                    style={{ padding: 0 }}
                    size="large"
                  >
                    <RHCloseIcon style={{ fontSize: 12 }} />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
          onChange={handleTypeahead}
          onKeyUp={handleEnterPressed}
          fullWidth
        />
      ) : (
        <RHTextField
          id="site-search-input"
          margin="normal"
          variant="standard"
          placeholder={!isRHRSearchField ? "SEARCH" : "Search"}
          autoFocus={true}
          InputProps={{
            autoComplete: "off",
            value: searchText,
            style: {
              textTransform: !isRHRSearchField ? "uppercase" : "none",
              background: "transparent"
            },
            className: classNames([
              typographyClasses.searchInput,
              classes.hoverSearch
            ]), // entered text will appear uppercase, but value will be as user types it
            endAdornment: (
              <InputAdornment position="start">
                {!!searchText && (
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={_onClearSearchText}
                    size="large"
                  >
                    <RHCloseIcon style={{ fontSize: 15 }} />
                  </IconButton>
                )}
                <IconButton disableRipple color="primary" size="large">
                  <RHSearchIcon style={{ fontSize: 17 }} />
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={handleTypeahead}
          onKeyUp={handleEnterPressed}
          fullWidth
        />
      )}
      {!!searchText && isRHRSearchField && (
        <div
          style={{
            marginTop: isMobileView ? 16 : 40,
            paddingBottom: isMobileView ? 10 : 0,
            position: "sticky",
            zIndex: 10,
            bottom: 0
          }}
        >
          <RHLink
            id="rhDoSearchLink"
            component={RouterLink}
            to={`/search/results.jsp?Ntt=${encodeURIComponent(
              searchText
            )}&Ns=product.sale%7C1`}
            onClick={rhLinkOnClickCb}
            color="secondary"
            underline="none"
          >
            <Typography
              style={{
                lineHeight: 1,
                fontSize: 11,
                color: theme.palette.common.black
              }}
            >
              {SEE_ALL}
              <RHArrowIcon
                strokeColor={theme.palette.common.black}
                style={{
                  height: 7,
                  width: 4,
                  marginLeft: 4
                }}
              />
            </Typography>
          </RHLink>
        </div>
      )}
      {(isHeaderSearch || showTypeAhead) && (
        <>
          <Backdrop
            onClick={backdropOnClickCb}
            style={{
              backgroundColor: RHR_BACKDROP_OVERLAY,
              zIndex: 9,
              marginTop: "60px"
            }}
            open={searchText.length > 0}
          />
          <div
            className={
              isHeaderSearch ? classes.hoverSearch : classes.overlaySearch
            }
          >
            <List disablePadding style={{ zIndex: 10 }} dense>
              {autoSuggestPromoResults.map(item => (
                <ListItem
                  key={item.promotionDisplay_name_t}
                  disableGutters
                  component={RHLink}
                  to={`${item.promotionDisplay_imageUrl_s}`}
                  onClick={() => {
                    analyticsLoader(a =>
                      a.emitAnalyticsEvent(
                        document.querySelector("#spa-root > *")! as HTMLElement,
                        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                        { searchTerm: item }
                      )
                    );
                    _onClose();
                  }}
                >
                  <ListItemText disableTypography>
                    <Typography
                      color="primary"
                      className={typographyClasses.searchAutoSuggestResult}
                      dangerouslySetInnerHTML={{
                        __html: formatDisplayName(item.promotionDisplay_name_t)
                      }}
                    />
                  </ListItemText>
                </ListItem>
              ))}
              {isRHRSearchField && autoSuggestCategoryResults.length > 0 && (
                <Typography
                  className={typographyClasses.autoSearchSectionTitle}
                >
                  {CATEGORIES}
                </Typography>
              )}
              {autoSuggestCategoryResults.map(item => {
                const isExternalHost = item.properties.isCrossBrand;
                const path = `/search/results.jsp${
                  item.navigationState
                }&sale=false&Ntt2=${encodeURIComponent(searchText)}`;
                return (
                  <ListItem
                    key={item.navigationState}
                    disableGutters
                    component={RHLink}
                    href={isExternalHost ? item.host + path : ""}
                    to={isExternalHost ? "" : path}
                    style={isRHRSearchField ? { padding: 0 } : {}}
                    onClick={() => {
                      analyticsLoader(a =>
                        a.emitAnalyticsEvent(
                          document.querySelector(
                            "#spa-root > *"
                          )! as HTMLElement,
                          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                          { searchTerm: item }
                        )
                      );
                      handleLinkClicked();
                    }}
                  >
                    <ListItemText
                      style={isRHRSearchField && !smDown ? { margin: 0 } : {}}
                      disableTypography
                    >
                      <Typography
                        className={
                          !isRHRSearchField
                            ? typographyClasses.searchAutoSuggestResult
                            : typographyClasses.rhrSearchAutoSuggestResult
                        }
                        dangerouslySetInnerHTML={{
                          __html: formatDisplayName(
                            item.label,
                            item.properties.parentCategoryName
                          )
                        }}
                      />
                    </ListItemText>
                  </ListItem>
                );
              })}

              {isRHRSearchField && autoSuggestProductResults.length > 0 && (
                <Typography
                  className={`${typographyClasses.autoSearchSectionTitle} product-title`}
                >
                  {PRODUCTS}
                </Typography>
              )}
              {autoSuggestProductResults.map(item => (
                <ListItem
                  key={item.product.repositoryId}
                  disableGutters
                  component={RHLink}
                  style={isRHRSearchField ? { padding: 0 } : {}}
                  to={
                    item.product.repositoryId === "Membership-Enrollment"
                      ? `/membership`
                      : `/catalog/product/product.jsp?productId=${
                          item.product.repositoryId
                        }&category=search&Ntt=${he.decode(
                          encodeURI(item.product.displayName)
                        )}&Ntt2=${encodeURIComponent(searchText)}`
                  }
                  onClick={() => {
                    analyticsLoader(a =>
                      a.emitAnalyticsEvent(
                        document.querySelector("#spa-root > *")! as HTMLElement,
                        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                        { searchTerm: item }
                      )
                    );
                    _onClose();
                  }}
                >
                  <ListItemText
                    style={isRHRSearchField && !smDown ? { margin: 0 } : {}}
                    disableTypography
                  >
                    <Typography
                      className={
                        !isRHRSearchField
                          ? typographyClasses.searchAutoSuggestResult
                          : typographyClasses.rhrSearchAutoSuggestResult
                      }
                      dangerouslySetInnerHTML={{
                        __html: formatDisplayName(item.product.displayName)
                      }}
                    />
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            {!!searchText && !isRHRSearchField && (
              <div
                style={{
                  margin: theme.spacing(1.6, 0),
                  position: "sticky",
                  zIndex: 10,
                  bottom: 0
                }}
              >
                <RHLink
                  id="rhDoSearchLink"
                  component={RouterLink}
                  to={`/search/results.jsp?Ntt=${encodeURIComponent(
                    searchText
                  )}&Ns=product.sale%7C1`}
                  onClick={rhLinkOnClickCb}
                  color="secondary"
                  underline="none"
                >
                  <Typography color="primary">
                    {SEE_ALL}{" "}
                    <RHArrowIcon
                      style={{
                        fontSize: 12,
                        width: isFeaturetailwindIcon
                          ? "16px !important"
                          : "12px !important",
                        height: isFeaturetailwindIcon
                          ? "16px !important"
                          : "12px !important"
                      }}
                    />
                  </Typography>
                </RHLink>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  function formatDisplayName(
    displayName: string,
    parentCategoryName?: string | null
  ) {
    displayName = decodeURIComponent(displayName);
    const matches = displayName.match(new RegExp(searchText, "i"));
    if (!!matches) {
      matches.forEach(match => {
        displayName = displayName.replace(match, `<strong>${match}</strong>`);
      });
    }

    if (!!parentCategoryName) {
      return `${displayName} in ${parentCategoryName}`;
    }

    return displayName;
  }
};

export default memoize(SearchField);
