import React, { FC, useMemo, useCallback, useState, useEffect } from "react";
import { DrawerProps } from "@mui/material/Drawer";
import { useQuery } from "@apollo/client";
import {
  queryCatalogNavigation,
  querySiteNavigation
} from "@RHCommerceDev/graphql-client/queries/navigation";
import yn from "yn";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { queryShopByRoom } from "@RHCommerceDev/graphql-client/queries/rooms";
import NavigationDialog from "@RHCommerceDev/dialog-navigation";
import { useKeycloak } from "@RHCommerceDev/utils-keycloak/KeyCloak";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import axios from "axios";
import footerMenu from "./footer-menu";
import he from "he";
import processSaleNavigation from "@RHCommerceDev/utils/processSaleNavigation";
import memoize from "@RHCommerceDev/utils/memoize";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useSite from "@RHCommerceDev/hooks/useSite";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import { getAccountNavigation } from "@RHCommerceDev/utils/getAccountNavigation";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import useAccountNavigation from "@RHCommerceDev/hooks/useAccountNavigation";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import getKeycloakLocale from "@RHCommerceDev/utils/getKeycloakLocale";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useDeleteSessionMutation } from "@RHCommerceDev/hooks/mutations";

const fIds = {
  BH: "fld-explorebeachhouse",
  SH: "fld-exploreskihouse",
  OD: "fld-exploreoutdoor",
  CN: "fld-explorecontemporary"
};

interface ExtraNavigationElemnt
  extends Partial<Omit<NavigationElement, "childCategories">> {
  childCategories?: Maybe<ExtraNavigationElemnt[]>;
  props?: any;
}

export interface MenuDrawerProps extends DrawerProps {
  catalogNavigation?: NavigationElement;
  shopByRoom?: PromoDisplay[];
  saleNavigation?: NavigationElement;
  siteNavigation?: NavigationElement;
  profileStatus?: ProfileStatus;
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
}

export const MenuDrawer: FC<MenuDrawerProps> = ({
  catalogNavigation,
  shopByRoom,
  saleNavigation,
  siteNavigation,
  profileStatus,
  onClose,
  ...rest
}) => {
  const { country } = useUserPreferences();

  const showMemberColumn = useMemo(() => {
    return countries?.[country]?.member;
  }, [country]);

  const { drawerAccountNavigation } = useAccountNavigation();
  const environment = useEnv();

  const shippingEnabled = yn(environment.FEATURE_INTERNATIONAL);

  let childCategories = useMemo(
    () =>
      catalogNavigation?.childCategories?.map(child => ({
        ...child,
        "data-gtm-id": `sub-nav-${child?.displayName}`,
        displayName: he.decode(child?.displayName?.toUpperCase() || "")
      })),
    [catalogNavigation]
  );
  const { keycloak } = useKeycloak();
  const locale = useLocale();
  const [deleteSession] = useDeleteSessionMutation();
  const { userType } = useRhUserAtomValue();

  const rhUser = useRhUserAtomValue();
  const {
    REACT_APP_KEYCLOAK_ORIGIN,
    REACT_APP_KEYCLOAK_PATH,
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_CLIENTID
  } = useEnv();
  const brandCode = useBrand();

  const { setStorageValueWrapper } = useCookiesWithPermission();

  const handleSignInCb = useCallback(() => {
    if (!processEnvServer) {
      setStorageValueWrapper({
        storageKey: "REACT_APP_KEYCLOAK_CLIENTID",
        value: REACT_APP_KEYCLOAK_CLIENTID
      });
    }

    keycloak.clientId = REACT_APP_KEYCLOAK_CLIENTID;

    keycloak?.login?.(
      window.location.href.includes("search/results.jsp")
        ? {
            redirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
            locale: getKeycloakLocale(locale)
          }
        : {
            locale: getKeycloakLocale(locale)
          }
    );
  }, [keycloak, setStorageValueWrapper, locale]);

  const handleSignOutCb = useCallback(() => {
    axios
      .post(
        `${REACT_APP_KEYCLOAK_ORIGIN}${REACT_APP_KEYCLOAK_PATH}/realms/${REACT_APP_KEYCLOAK_REALM}/rhlogout`,
        null,
        {
          withCredentials: true,
          transformRequest: [
            (data, headers) => {
              delete headers.post["Content-Type"];
              headers.common["Accept"] = "application/json";
              return data;
            }
          ],
          headers: {
            authorization: `Bearer ${keycloak?.token}`
          }
        }
      )
      .then(async response => {
        await deleteSession();
        return await keycloak?.logout?.();
      })
      .catch(error => console.log(error));
  }, [
    REACT_APP_KEYCLOAK_ORIGIN,
    REACT_APP_KEYCLOAK_PATH,
    REACT_APP_KEYCLOAK_REALM,
    keycloak
  ]);

  const accountMenuItems = useCallback(() => {
    if (["TRADE", "CONTRACT"].includes(userType)) return [];
    else {
      return !keycloak?.authenticated
        ? [
            {
              id: "/sign-in",
              displayName: "SIGN IN OR REGISTER",
              props: {
                onClick: handleSignInCb
              }
            }
          ]
        : [
            {
              id: "/my-account/index.jsp",
              targetUrl: "/my-account/index.jsp",
              displayName: `
          <p style="margin: 0; line-height: 16px">MY ACCOUNT</p>
            <p id="welcome-message" style="margin: 0; font-size: 14px; letter-spacing: 0px; font-weight: 100">Hi ${rhUser?.firstName}</p>
        `,
              childCategories: [
                ...getAccountNavigation(
                  drawerAccountNavigation,
                  showMemberColumn
                ),
                {
                  id: "sign-out",
                  targetUrl: "", // falsey
                  displayName: "SIGN OUT",
                  props: {
                    onClick: handleSignOutCb
                  }
                }
              ]
            }
          ];
    }
  }, [rhUser, drawerAccountNavigation, keycloak?.authenticated]);

  const { protocol } = window.location;

  const galleriesPath =
    brandCode === "RH" ? "/galleries" : `${protocol}//rh.com/galleries`;
  const restaurantsPath =
    brandCode === "RH" ? "/restaurants" : `${protocol}//rh.com/restaurants`;

  const extraChildCategories: ExtraNavigationElemnt[] = [
    ...(shopByRoom
      ? [
          {
            id: "/rooms.jsp",
            targetUrl: "/rooms.jsp",
            displayName: "SHOP ROOMS",
            childCategories: shopByRoom.map(room => ({
              id: room.id,
              targetUrl: `/rooms.jsp?id=${room.id}`,
              displayName: room.title ?? ""
            }))
          }
        ]
      : []),
    ...(saleNavigation
      ? [
          {
            id: "/catalog/sale/index.jsp",
            targetUrl: "/catalog/sale/index.jsp",
            displayName: "SALE",
            childCategories: saleNavigation.childCategories
          }
        ]
      : []),
    {
      id: "divider"
    },
    ...accountMenuItems(),
    ...(siteNavigation
      ? [
          { id: "divider" },
          ...(siteNavigation?.childCategories ?? []).map(childCategory => ({
            ...childCategory,
            "data-gtm-id": `uber-nav-${childCategory.displayName}`,
            displayName:
              childCategory.displayName.toUpperCase() === "RH"
                ? childCategory.displayName.toUpperCase()
                : "RH " + childCategory.displayName.toUpperCase()
          }))
        ]
      : []),
    { id: "divider" },
    {
      id: "/galleries",
      "data-gtm-id": "footer-nav-galleries",
      targetUrl: galleriesPath,
      displayName: "RH GALLERIES"
    },
    {
      id: "/restaurants",
      "data-gtm-id": "footer-nav-restaurants",
      targetUrl: restaurantsPath,
      displayName: "RH RESTAURANTS"
    },
    ...footerMenu()
  ];

  return (
    <NavigationDialog
      {...rest}
      anchor="right"
      navigation={
        {
          ...catalogNavigation,
          displayName: "",
          childCategories: [
            ...(shippingEnabled
              ? [
                  {
                    id: "/shippingParent",
                    displayName: `SHIP TO ${country}`,
                    imgSrc: countries[country].flag,
                    childCategories: [
                      {
                        id: "shipping",
                        displayName: ""
                      }
                    ]
                  },
                  { id: "divider" }
                ]
              : []),
            ...(childCategories ?? []),
            ...(extraChildCategories as NavigationElement[])
          ]
        } as NavigationElement
      }
      onBack={() => onClose?.({}, "backdropClick")}
    />
  );
};

const MD = memoize(MenuDrawer);

MenuDrawer.defaultProps = {};

export default memoize((props: DrawerProps) => {
  // const nextgenPg =" useIsoCookies(["nextgenpg"])?.nextgenpg";
  //defaulting it to NG
  const nextgenPg = "true";
  const brand = useBrand();
  const siteId = useSite();
  const { userType } = useRhUserAtomValue();
  const [childCategories, setChildCategories] = useState<NavigationElement[]>(
    []
  );
  const contractTradeTypes = ["CONTRACT", "TRADE"];
  const isContractTrade = contractTradeTypes.includes(userType);
  const locale = useLocale();
  const countryCode = useCountry();

  const { data: { catalogNavigation } = {} as Query, refetch } =
    useQuery<Query>(queryCatalogNavigation, {
      variables: {
        siteId,
        countryCode,
        nextgenPg: nextgenPg || "false"
      }
    });

  const { data: { shopByRoom } = {} as Query } = useQuery<Query>(
    queryShopByRoom,
    {
      variables: {
        id: fIds[brand],
        countryCode: countryCode
      }
    }
  );

  const {
    data: { catalogNavigation: saleNavigation } = {} as Query,
    refetch: refetchSaleNav
  } = useQuery<Query>(queryCatalogNavigation, {
    variables: {
      filter: "sale",
      siteId,
      countryCode,
      nextgenPg: nextgenPg || "false"
    }
  });

  const { data: { siteNavigation } = {} as Query } = useQuery<Query>(
    querySiteNavigation,
    {
      variables: {
        siteId,
        locale
      }
    }
  );

  useDidMountEffect(() => {
    refetch({
      siteId
    });
    refetchSaleNav({
      siteId
    });
  }, [siteId]);

  useEffect(() => {
    const filterCategories =
      catalogNavigation &&
      catalogNavigation.childCategories.filter(
        ({ displayName }) => !displayName.toLowerCase().includes("sale")
      );
    if (filterCategories && isContractTrade && siteId === "TN") {
      const childCategories = filterCategories.filter(
        e => e.targetUrl !== "/my-account/wish-list.jsp"
      );
      setChildCategories(childCategories);
    } else {
      setChildCategories(filterCategories);
    }
  }, [catalogNavigation]);

  return (
    <MD
      {...props}
      catalogNavigation={{
        ...catalogNavigation,
        childCategories
      }}
      shopByRoom={shopByRoom}
      saleNavigation={processSaleNavigation(saleNavigation)}
      siteNavigation={siteNavigation}
      onClose={() => props.onClose?.({}, "backdropClick")}
    />
  );
});
