import { BREAKPOINT_LG, BREAKPOINT_SM } from "utils/constants";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      ["&&"]: {
        left: "unset",
        right: "10px"
      }
    },
    titlePadding: {
      "&&": {
        padding: theme.spacing(2, 4, 3, 5),
        [theme.breakpoints.down(BREAKPOINT_LG)]: {
          padding: theme.spacing(2, 4, 3, 5)
        }
      }
    },
    drawer: {
      "& .MuiDialogContent-root": {
        padding: `${theme.spacing(5, 10)} !important`
      },
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0, 5)} !important`
        }
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0, 4)} !important`
        }
      }
    }
  })
);
