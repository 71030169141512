import React, { FC, useState, useEffect, useCallback } from "react";
import { Grid, List, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ListItemWithChildren from "@RHCommerceDev/container-inline-header/ListItemWithChildren";
import TerminalListItem from "@RHCommerceDev/container-inline-header/TerminalListItem";
import memoize from "@RHCommerceDev/utils/memoize";
import useParams from "@RHCommerceDev/hooks/useParams";
import { isTouchDevice } from "@RHCommerceDev/utils/checkTouchDevice";

interface StyleProps {
  darkTheme: boolean;
}
const useStyles = (props: StyleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        backgroundColor: props.darkTheme
          ? theme.palette.common.black
          : "transparent",
        color: props.darkTheme
          ? theme.palette.common.white
          : theme.palette.common.black
      },
      levelOne: {
        marginTop: 30,
        marginBottom: 30,
        borderRight: "1px solid #CCC"
      },
      levelTwo: {
        marginTop: 30,
        marginBottom: 30,
        backgroundColor: props.darkTheme ? "#111111" : undefined
      }
    })
  );

export interface MenuPopperContentProps {
  navigationElement: Maybe<NavigationElement>;
  onLinkClick: (event: React.MouseEvent) => void;
  hoverStyle?: "underline" | "highlight";
  darkTheme?: boolean;
}

export const MenuPopperContent: FC<MenuPopperContentProps> = ({
  navigationElement,
  onLinkClick,
  hoverStyle,
  darkTheme = false
}) => {
  const classes = useStyles({ darkTheme })();
  const theme = useTheme();
  const [activeChild, setActiveChild] = useState<Maybe<NavigationElement>>(
    navigationElement?.childCategories?.[0]
  );
  const [, setPendingActiveChild] = useState<Maybe<NavigationElement>>(null);
  const [openTimeout, setOpenTimeout] = useState<number | null>(null);
  const [{ brandLinkIndex }, setBrandLink] = useState({
    brandLinkIndex: 0
  });

  const params = useParams({
    topCatId: "",
    parentCatId: ""
  });
  useEffect(() => {
    setActiveChild(navigationElement?.childCategories?.[0]);

    // set first brandLink
    let brandLink = { exists: false, i: 0 };

    navigationElement?.childCategories?.map((childCategory, i) => {
      if (
        childCategory.id === params.parentCatId &&
        navigationElement.id === params.topCatId
      ) {
        setActiveChild(childCategory);
      }

      if (
        !brandLink.exists &&
        childCategory.displayName.toLowerCase().includes("shop rh")
      ) {
        brandLink.exists = true;
        brandLink.i = i;
      }
    });
    setBrandLink({
      brandLinkIndex: brandLink.i
    });
  }, [navigationElement, setActiveChild]);

  const clearOpenTimeout = useCallback(() => {
    if (openTimeout) {
      window.clearTimeout(openTimeout);
      setOpenTimeout(null);
      setPendingActiveChild(null);
    }
  }, [openTimeout]);

  const onChildClick = useCallback(
    (navElement: NavigationElement) => {
      setActiveChild(navElement);
      clearOpenTimeout();
    },
    [clearOpenTimeout]
  );

  const doActivePendingChild = useCallback((navElement: NavigationElement) => {
    setActiveChild(navElement);
    setPendingActiveChild(null);
  }, []);

  const onChildMouseLeave = useCallback(
    (navElement: NavigationElement) => {
      clearOpenTimeout();
    },
    [clearOpenTimeout]
  );

  const onChildDelayActivate = useCallback(
    (navElement: NavigationElement) => {
      if (openTimeout) {
        window.clearTimeout(openTimeout);
      }
      setPendingActiveChild(navElement);
      const timeout = window.setTimeout(
        () => {
          doActivePendingChild(navElement);
        },
        isTouchDevice ? 0 : 200
      );
      setOpenTimeout(timeout);
    },
    [activeChild, openTimeout, doActivePendingChild]
  );

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.levelOne}>
        <List disablePadding>
          {navigationElement?.childCategories?.map((childCategory, i) => {
            if (!childCategory.childCategories?.length) {
              return (
                <TerminalListItem
                  topCat={navigationElement}
                  key={`category-link-${childCategory.id}`}
                  childCategory={childCategory}
                  onClick={onLinkClick}
                  hoverStyle={hoverStyle}
                />
              );
            }
            return (
              <ListItemWithChildren
                key={`category-link-${childCategory.id}`}
                childCategory={childCategory}
                onClick={onChildClick}
                onMouseEnter={onChildDelayActivate}
                onMouseLeave={onChildMouseLeave}
                active={activeChild?.id === childCategory.id}
                hoverStyle={hoverStyle}
                topCatId={navigationElement.id}
                firstBrandChild={brandLinkIndex === i}
                dividerProps={{
                  backgroundColor: darkTheme
                    ? theme.palette.common.white
                    : theme.palette.common.black
                }}
              />
            );
          })}
        </List>
      </Grid>
      <Grid item xs={6} className={classes.levelTwo}>
        <List disablePadding>
          {activeChild?.childCategories?.map(childLink => (
            <TerminalListItem
              topCat={navigationElement}
              parentCat={activeChild}
              key={`category-link-${childLink.id}`}
              childCategory={childLink}
              onClick={onLinkClick}
              hoverStyle={hoverStyle}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default memoize(MenuPopperContent);
