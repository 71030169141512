import React, { FC, useState, useRef, useCallback, CSSProperties } from "react";
import { Grid, useTheme, Menu, MenuItem } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useNavigationStylesByBrand } from "@RHCommerceDev/hooks/useTypographyStyles";
import { inlineAccountNavigation } from "data/accountNavigation";
import NavigationLink from "@RHCommerceDev/container-inline-header/NavigationLink";
import classNames from "classnames";
import memoize from "@RHCommerceDev/utils/memoize";
import RHAccountIcon from "@RHCommerceDev/icon-account";
import { useAuth } from "@RHCommerceDev/hooks/useAuth";
import ConfirmDialog from "@RHCommerceDev/dialog-confirm";
import { authPages } from "@RHCommerceDev/resources/my-account-messaging.json";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { getAccountNavigation } from "@RHCommerceDev/utils/getAccountNavigation";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useKeycloak } from "@react-keycloak/web";
import { useUserSessionAtomValue } from "@RHCommerceDev/hooks/atoms";

const useStyles = makeStyles(
  createStyles({
    arrowIconNavOut: {
      transform: "rotate(90deg)"
    },
    arrowIconNavIn: {
      transform: "rotate(-90deg)"
    },
    menuItem: {
      paddingLeft: 40,
      paddingRight: 40,
      textTransform: "uppercase",
      "& .menu-item-child": {
        paddingBottom: 2,
        borderBottom: "1px solid transparent",
        lineHeight: 1
      },
      "&.MuiListItem-button:hover .menu-item-child": {
        borderBottomColor: "black"
      },

      "&.MuiMenuItem-root": {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    icon: {
      color: "inherit",
      height: 12,
      width: 12,
      marginRight: 8
    },
    profileFullName: {
      fontSize: 12,
      lineHeight: 1,
      color: "#808080",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      padding: "0 40px 2px 40px"
    }
  })
);

export interface AccountNavMenuProps {
  profileStatus?: ProfileStatus;
  className?: string;
  darkTheme?: boolean;
  style?: CSSProperties;
  /** @deprecated unused */
  rhrAccountIcon?: boolean;
}

const SignedOut: FC<Omit<AccountNavMenuProps, "className">> = () => {
  const { signIn } = useAuth();
  const classes = useStyles();

  return (
    <NavigationLink
      to=""
      id="container-inlineHeader-accountNavMenu_account-btn"
      onClick={e => {
        e.preventDefault();
        signIn();
      }}
    >
      {<RHAccountIcon className={classes.icon} />}
    </NavigationLink>
  );
};

const SignedIn: FC<Omit<AccountNavMenuProps, "className">> = memoize(
  ({ darkTheme = false }) => {
    const typographyClasses = useNavigationStylesByBrand({
      keys: ["desktopNavLink"]
    });
    const [menuOpen, setMenuOpen] = useState(false);
    const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

    const anchorEl = useRef(null);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const closeMenuCb = useCallback(() => setMenuOpen(false), []);
    const openMenuCb = useCallback(() => setMenuOpen(true), []);

    const { firstName, lastName } = useRhUserAtomValue();
    const { signOut, signedOut, loading } = useAuth();
    const brand = useBrand();

    const onMenuItemClickCb = useCallback(child => {
      if (child.id === "/signout") {
        return setOpenSignOutDialog(true);
      }

      navigate(child.targetUrl);
    }, []);

    const showMemberColumn = countries[getCountryFromUrl() || "US"].member;
    return (
      <>
        <ConfirmDialog
          title={authPages.signOutConfirm}
          loading={loading}
          buttonText={authPages.signOut}
          onClick={signOut}
          open={openSignOutDialog}
          onClose={() => setOpenSignOutDialog(false)}
          succeed={signedOut}
          succeedText={authPages.succeed}
        />

        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={openMenuCb}
          ref={anchorEl}
          id="container-inlineHeader-accountNavMenu_account-btn"
        >
          <RHAccountIcon className={classes.icon} />
        </div>
        <Menu
          PaperProps={{
            square: true,
            style: {
              marginTop: 10,
              boxShadow: "0px 1px 2px 2px rgba(0, 0, 0, 0.1)",
              backgroundColor: darkTheme
                ? theme.palette.common.black
                : theme.palette.common.white,
              color: darkTheme
                ? theme.palette.common.white
                : theme.palette.common.black
            },
            onMouseLeave: closeMenuCb
          }}
          MenuListProps={{
            style: {
              padding: "22px 0"
            }
          }}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
          transformOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
          elevation={0}
          anchorEl={anchorEl.current}
          open={menuOpen}
          onClose={closeMenuCb}
        >
          {
            <li className={classes.profileFullName}>
              Hi, {firstName ? firstName : ""} {lastName ? lastName : ""}
            </li>
          }

          {getAccountNavigation(inlineAccountNavigation, showMemberColumn).map(
            child =>
              !(
                child?.targetUrl?.includes("gift-registry") && brand === "TN"
              ) ? (
                <MenuItem
                  key={`account-menu-link-${child.id}`}
                  id={`account-menu-link-${child.id}`}
                  disableGutters
                  onClick={() => onMenuItemClickCb(child)}
                  style={{
                    backgroundColor: "transparent",
                    marginTop: 12
                  }}
                  className={classes.menuItem}
                  data-navigation-account-item="true"
                  data-navigation-account-item-id={child.id}
                >
                  <span
                    className={`${classNames(
                      typographyClasses.desktopNavLink
                    )} ${"menu-item-child"}`}
                  >
                    {child.displayName}
                  </span>
                </MenuItem>
              ) : null
          )}
        </Menu>
      </>
    );

    function navigate(to: string) {
      history.push(to);
    }
  }
);

export const AccountNavMenu: FC<AccountNavMenuProps> = ({
  profileStatus,
  className,
  darkTheme,
  style
}) => {
  const { loading } = useUserSessionAtomValue();
  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();
  const nonRegularSignIn = ["TRADE", "CONTRACT"].includes(userType || "");

  if (nonRegularSignIn || loading) {
    return null;
  }

  return (
    <Grid style={style} item className={className}>
      {keycloak?.authenticated ? (
        <SignedIn darkTheme={darkTheme} profileStatus={profileStatus} />
      ) : (
        <SignedOut darkTheme={darkTheme} />
      )}
    </Grid>
  );
};

export default memoize(AccountNavMenu);
