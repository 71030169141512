import React, { FC, useCallback } from "react";
import { ListItem, useTheme } from "@mui/material";
import classNames from "classnames";
import { useNavigationStylesByBrand } from "@RHCommerceDev/hooks/useTypographyStyles";
import he from "he";
import useParams from "@RHCommerceDev/hooks/useParams";
import { isTouchDevice } from "@RHCommerceDev/utils/checkTouchDevice";
import memoize from "@RHCommerceDev/utils/memoize";
import { useStyles as useNavigationStyles } from "@RHCommerceDev/container-rhr-header/RHRCatalogNavigationDetails/style";

export interface ListItemWithChildrenProps {
  childCategory: NavigationElement;
  onClick: (navElement: NavigationElement) => void;
  onMouseEnter?: (navElement: NavigationElement) => void;
  onMouseLeave?: (navElement: NavigationElement) => void;
  active?: boolean;
  hoverStyle?: "underline" | "highlight";
  topCatId?: string;
  firstBrandChild?: boolean;
  dividerProps?: React.CSSProperties;
}

export const ListItemWithChildren: FC<ListItemWithChildrenProps> = ({
  childCategory,
  onClick,
  onMouseEnter,
  onMouseLeave,
  active,
  hoverStyle,
  topCatId,
  firstBrandChild,
  dividerProps
}) => {
  const navClasses = useNavigationStyles();
  const theme = useTheme();

  const typographyStyles = useNavigationStylesByBrand({
    keys: ["desktopNavLink", "desktopNavLinkHighlight"]
  });
  const params = useParams({
    topCatId: "",
    parentCatId: ""
  });
  const brandLink = childCategory.displayName.includes("Shop RH");
  const onClickFactory = useCallback(
    (navElement: NavigationElement) => (event: React.MouseEvent) =>
      onClick(navElement),
    [onClick]
  );
  const onMouseEnterFactory = useCallback(
    (navElement: NavigationElement) => (event: React.MouseEvent) => {
      if (onMouseEnter) {
        onMouseEnter(navElement);
      }
    },
    [onMouseEnter]
  );
  const onMouseLeaveFactory = useCallback(
    (navElement: NavigationElement) => (event: React.MouseEvent) => {
      if (onMouseLeave) {
        onMouseLeave(navElement);
      }
    },
    [onMouseLeave]
  );
  const events = {
    [isTouchDevice ? "onPointerDown" : "onMouseEnter"]:
      onMouseEnterFactory(childCategory),
    ...(isTouchDevice
      ? {}
      : { onMouseLeave: onMouseLeaveFactory(childCategory) })
  };
  return (
    <ListItem
      disableGutters
      data-navigation-item="true"
      data-navigation-item-id={childCategory.id}
      onClick={onClickFactory(childCategory)}
      {...events}
      style={{
        paddingTop:
          firstBrandChild && brandLink ? theme.typography.pxToRem(30) : ""
      }}
    >
      <div style={{ marginLeft: theme.typography.pxToRem(32) }}>
        <div
          style={{
            textTransform: brandLink ? "uppercase" : "none",
            fontSize: "0.9166666666666666rem",
            fontFamily: "RHSans-Light, Helvetica, Arial",
            fontWeight: 400,
            letterSpacing: "0.04em"
          }}
          className={classNames(
            navClasses.onHover,
            navClasses.extraLineHeight,
            navClasses.capitalize,
            {
              [navClasses.selected]:
                (childCategory.id === params.parentCatId &&
                  topCatId === params.topCatId) ||
                (childCategory.id !== params.parentCatId &&
                  topCatId !== params.topCatId &&
                  active)
            }
          )}
          data-navigation-item-typography="true"
          data-navigation-item-typography-id={childCategory.id}
          dangerouslySetInnerHTML={{
            __html: he.decode(childCategory.displayName.replace(/<br>/g, ""))
          }}
        />
      </div>
    </ListItem>
  );
};

export default memoize(ListItemWithChildren);
