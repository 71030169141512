import AEMWrapper from "@RHCommerceDev/aem/ComponentWrapper";
import { AEMComponentProps } from "@RHCommerceDev/aem/types";
import React, { FC } from "react";
// import loadable from "@loadable/component";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { useMediaQuery, Theme } from "@mui/material";

// const Header = loadable(
//   () => import(/* webpackChunkName: "container-header-switcher" */ "."),
//   {
//     fallback: <></>
//   }
// );

import Header from ".";

export type AEMHeaderProps = AEMComponentProps & {
  desktopColorStyle: string;
  desktopPosition: "relative" | "absolute";
  mobileColorStyle: string;
  mobilePosition: "relative" | "absolute";
  mobileBackgroundColorStyle: string;
  desktopBackgroundColorStyle: string;
  paddingBottom: string;
  isInEditor?: boolean;
};

const env = getGlobal("_env");

const Component: FC<AEMHeaderProps> = ({
  desktopColorStyle = "White",
  desktopPosition = "relative",
  mobileColorStyle = "White",
  mobilePosition = "relative",
  mobileBackgroundColorStyle = "transparent",
  desktopBackgroundColorStyle = "transparent",
  isInEditor
}) => {
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const mobileBg =
    mobileBackgroundColorStyle === "White Gradient"
      ? "linear-gradient(to bottom, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.44) 59%, rgba(0,0,0,0) 100%"
      : mobileBackgroundColorStyle;
  const desktopBg =
    desktopBackgroundColorStyle === "White Gradient"
      ? "linear-gradient(to bottom, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.44) 59%, rgba(0,0,0,0) 100%"
      : desktopBackgroundColorStyle;

  const position = mdUp ? desktopPosition : mobilePosition;
  const background = mdUp ? desktopBg : mobileBg;
  const color = mdUp ? desktopColorStyle : mobileColorStyle;

  return (
    <>
      <Header
        isInEditor={isInEditor}
        RHRHeaderProps={{
          RHRHeaderContentProps: {
            styles: {
              root: {
                position,
                background,
                color
              }
            }
          }
        }}
        DrawerHeaderProps={{
          DrawerHeaderContentProps: {
            skipAboveNavPromoDisplay: true,
            styles: {
              root: {
                background: mobileBg,
                backgroundColor: mobileBg,
                position: mobilePosition
              }
            },
            LogoProps: {
              primary: true,
              customColor: mobileColorStyle
            }
          }
        }}
        InlineHeaderProps={{
          InlineHeaderContentProps: {
            styles: {
              backgroundColorStyle:
                desktopBackgroundColorStyle === "White" ? "light" : "dark",
              colorStyle: desktopColorStyle === "White" ? "light" : "dark",
              root: {
                position: desktopPosition,
                background: desktopBg
              },
              divider: {
                backgroundColor: desktopColorStyle,
                opacity: 0.4
              }
            },
            LogoProps: {
              primary: true,
              customColor: desktopColorStyle
            },
            CatalogNavigationProps: {
              styles: {
                root: {
                  color: desktopColorStyle
                }
              }
            },
            OtherNavigationProps: {
              styles: {
                root: {
                  color: desktopColorStyle
                },
                link: {
                  root: {
                    color: desktopColorStyle
                  }
                }
              }
            },
            SiteNavigationProps: {
              styles: {
                root: {
                  color: desktopColorStyle
                },
                link: {
                  root: {
                    color: desktopColorStyle
                  },
                  typography: {
                    color: desktopColorStyle
                  }
                }
              }
            }
          }
        }}
      />
    </>
  );
};

export default AEMWrapper(
  {
    type: "rh/components/content/headerswitcher",
    config: {
      emptyLabel: "HeaderSwitcher",
      isEmpty: _ => false
    }
  },
  Component
);
